import { navigate } from 'gatsby';
import { useState } from 'react';
import { useQueryParam } from '@/hooks/useQueryParam';
import AuthService from '@/services/auth';
import RoutePaths from '@/utils/routes';
import { SignAsParams } from '..';

const useHandleSignAs = (redirectToDashboard = true) => {
  const params = useQueryParam<SignAsParams>();
  const [loading, setLoading] = useState<boolean>(true);

  const signInAs = async (uid: string) => {
    try {
      if (!loading) setLoading(true);
      await AuthService.loginAsAdmin(uid);
      if (redirectToDashboard) navigate(RoutePaths.MAILING_LISTS);
    } catch (error) {
      navigate(RoutePaths.NOT_FOUND);
    } finally {
      setLoading(false);
    }
  };

  return {
    signInAs,
    loading,
    params,
  };
};

export default useHandleSignAs;
