import React, { useLayoutEffect } from 'react';
import Loadable from 'react-loadable';
import { useDispatch } from 'react-redux';
import { LoadingPage } from '@/components/loading/loadingBase';
import PrivateProvider from '@/components/providers/PrivateProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';
import AppAction from '@/reducers/app/action';
import useHandleSignAs from '@/sections/SignAs/hooks/useHandleSignAs';
import { forceLogout } from '@/utils/auth';

interface RequestCampaignPageProps {
  params: { id: string; uid: string };
}

const RequestCampaignLoadable = Loadable({
  loader: () => import('@/sections/Broker/Campaign'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});

const RequestCampaignPage: React.FC<RequestCampaignPageProps> = ({ params }) => {
  const { loading, params: queryParams, signInAs } = useHandleSignAs(false);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (!queryParams.uid) return;
    forceLogout(false);
    dispatch({
      type: AppAction.RESET_ROOT_STATE,
    });

    signInAs(queryParams.uid);
  }, [queryParams.uid]);

  return (
    <React.Fragment>
      <HeaderMetadata title="Broker - Request to send Campaign" />
      {loading ? (
        <LoadingPage isLoading={loading} />
      ) : (
        <PrivateProvider>
          <RequestCampaignLoadable {...params} />
        </PrivateProvider>
      )}
    </React.Fragment>
  );
};

export default RequestCampaignPage;
