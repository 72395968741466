import { apiIns } from '@/config/apiClient';
import { LoginParams } from './model';
import { JwtToken } from '@goldfishcode/first-team-real-estate-sdk/libs/api/auth/models';

class AuthService {
  static signIn(info: LoginParams): Promise<unknown> {
    return apiIns.auth.login(info.username, info.password);
  }
  static verifyEmail(key: string): Promise<unknown> {
    return apiIns.auth.verifyEmail(key);
  }
  static resendEmail(email: string): Promise<unknown> {
    return apiIns.auth.resendEmail(email);
  }
  static resetPassword(email: string): Promise<unknown> {
    return apiIns.auth.resetPassword(email);
  }
  static resetPasswordConfirm(newPassword: string, uid: string, token: string): Promise<unknown> {
    return apiIns.auth.resetPasswordConfirm(newPassword, uid, token);
  }
  static changePassword(oldPassword: string, newPassword: string): Promise<unknown> {
    return apiIns.auth.changePassword(oldPassword, newPassword);
  }
  static loginAsAdmin(uid: string): Promise<JwtToken> {
    return apiIns.auth.loginAsAdmin(uid);
  }
}

export default AuthService;
